<template>
  <div class="config-table">
    <b-table :items="seasons" :fields="fields" responsive="sm" small>
      <template #cell(season)="data">Season {{ data.index + 1 }}</template>

      <template #cell(start_time)="data">
        <date-picker
          v-if="seasonId == data.index"
          v-model="data.item.start_time"
          type="date"
          placeholder="Start time"
        />
        <p v-else>{{ data.item.start_time | local_time_string }}</p>
      </template>

      <template #cell(end_time)="data">
        <date-picker
          v-if="seasonId == data.index"
          v-model="data.item.end_time"
          type="date"
          placeholder="End time"
        />
        <p v-else>{{ data.item.end_time | local_time_string }}</p>
      </template>

      <template #cell(image)="data">
        <b-form-input v-if="seasonId == data.index" v-model="data.item.image" placeholder="image" />
        <img v-else :src="data.item.image" alt="season img" width="160">
      </template>

      <template #cell(action)="data">
        <div class="d-flex">
          <b-button
            @click="editOrUpdateConfig(data.index)"
            size="sm"
            style="margin-right: 5px"
            variant="primary"
          >{{ seasonId == data.index ? "Save" : "Edit" }}</b-button>
          <b-button
            size="sm"
            variant="danger"
            @click="confirmDelete(data.index)"
          >{{ seasonId == data.index ? "Cancel" : "Delete" }}</b-button>
        </div>
      </template>
    </b-table>
    <div class="add-season">
      <b-button class="text-center" variant="primary" @click="addSeason">
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span>Add season</span>
      </b-button>
    </div>
  </div>
</template>
<script>
import service from "../../service";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    DatePicker,
  },

  props: {
    config: { type: Object, default: () => { } },
  },

  data() {
    return {
      fields: [
        "season",
        "start_time",
        "end_time",
        "image",
        { key: "action", label: "" },
      ],
      seasonId: null,
      seasons: []
    };
  },

  watch: {
    config(value) {
      this.seasons = JSON.parse(JSON.stringify(value.value))
    }
  },

  methods: {
    getMonday(d) {
      d = new Date(d);
      var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday

      return new Date(d.setDate(diff));
    },

    getSunday(d) {
      d = this.getMonday(d);
      
      return new Date(d.setDate(d.getDate() + 6));
    },

    checkDateGreaterOrEqual(dateA, dateB) {
      return new Date(dateA) - new Date(dateB) >= 0
    },

    validateTimeSeason(index) {
      if (!this.seasons[index].start_time || !this.seasons[index].end_time) {
        this.$store.dispatch("pushErrorNotify", {
          text: "Season time is invalid!",
        });

        return false
      }
      
      if (this.checkDateGreaterOrEqual(this.seasons[index].start_time, this.seasons[index].end_time)) {
        this.$store.dispatch("pushErrorNotify", {
          text: "End time must be greater than start time!",
        });

        return false
      }

      if (this.seasons[index - 1] && this.checkDateGreaterOrEqual(this.getSunday(this.seasons[index - 1].end_time), this.seasons[index].start_time)) {
        this.$store.dispatch("pushErrorNotify", {
          text: "Season must not be included in the previous season!",
        });

        return false
      }

      if (this.seasons[index + 1] && this.checkDateGreaterOrEqual(this.seasons[index].end_time, this.getMonday(this.seasons[index + 1].start_time))) {
        this.$store.dispatch("pushErrorNotify", {
          text: "Season must not be included in the next season!",
        });

        return false
      }
      if (this.seasons[index].end_time.getDay() !== 1){
        this.$store.dispatch("pushErrorNotify", {
          text: "Season end time must be Monday!",
        });
        return false
      }
      return true
    },

    async editOrUpdateConfig(index) {
      if (!(this.seasonId + '') || this.seasonId != index) {
        this.seasonId = index;
        this.seasons[index].start_time = new Date(this.seasons[index].start_time)
        this.seasons[index].end_time = new Date(this.seasons[index].end_time)
      } else {
        if (!this.validateTimeSeason(index)) {
          return
        }

        const response = await this.updateSeasons();
        if (response.data.type == "SUCCESS") {
          this.resetData();
          this.$emit("getSeasons");
        }
      }
    },

    async updateSeasons() {
      const dataUpdate = {
        ...this.config,
        value: this.seasons,
      }

      return await service.update(dataUpdate);
    },

    async cancelOrDelete(id) {
      if (this.seasonId == id) {
        this.resetData();
      } else {
        this.seasons = [...this.seasons.splice(0, id), ...this.seasons.slice(1)]
        await this.updateSeasons()
        this.$emit("getSeasons");
      }
    },

    confirmDelete(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure ?", {
          title: "Please Confirm",
          size: "md",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.cancelOrDelete(id);
          }
        });
    },

    resetData() {
      this.seasonId = null;
      this.seasons = JSON.parse(JSON.stringify(this.config.value))
    },

    addSeason() {
      this.seasons.push({})
      this.seasonId = this.seasons.length - 1
    }
  },
};
</script>
<style lang="scss">
.config-table {
  table {
    td {
      padding-top: 0.72rem !important;
      padding-bottom: 0.72rem !important;
      p {
        margin: unset;
      }
    }
  }
  .add-season {
    width: 100%;
    button {
      margin: auto;
      display: block;
    }
  }
}
</style>
