<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Season" class="mb-3" />
      </b-col>
    </b-row>
    <b-media>
      <section class="py-3">
        <b-card>
          <season-table :config="seasonConfig" @getSeasons="getSeasons" />
        </b-card>
      </section>
    </b-media>
    <b-pagination
      class="mt-auto"
      pills
      size="lg"
      align="center"
      v-model="current_page"
      :total-rows="total_items"
      :per-page="items_perpage"
    />
  </div>
</template>

<script>
import configService from "../../../config/service";
import { GOGA_RANKING_SEASON_TIME_CONFIG, MODULE_CATEGORY } from '../constants'
import SeasonTable from "./_components/SeasonTable.vue";

export default {
  components: {
    SeasonTable,
  },
  data() {
    return {
      current_page: 1,
      total_items: 0,
      items_perpage: 50,
      seasonConfig: {},
    };
  },
  created() {
    this.getSeasons()
  },
  methods: {
    async getSeasons() {
      this.seasonConfig = await configService.getByKey({ key: GOGA_RANKING_SEASON_TIME_CONFIG.key }, { default_data: GOGA_RANKING_SEASON_TIME_CONFIG.default_data, category: MODULE_CATEGORY })
    },
  },
};
</script>
